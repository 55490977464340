body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #FFF4F4;
}

b {
  color: #00C9C9
}

p {
  font-size: x-large;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  text-align: center; /* Center inline content inside .content */
}


html {
  background-color: #201c1c;
  font-family: 'Hack', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-size: medium;
  color: #Eeebeb;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.dropdown ul li {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.dropdown ul li:hover {
  background-color: #201c1c;
}
